import { Webapps } from '@bs/models/cms/webapps';
import { EnvironmentConfig } from '@bs/models/common/environment-config';
import packageInfo from 'package.json';

export const environment: EnvironmentConfig = {
  api: {
    accounts: 'https://api-accounts-stg.betstarters.cloud',
    boe: 'https://api-boe-stg.betstarters.cloud',
    catalogs: 'https://api-catalogs-stg.betstarters.cloud',
    cms: 'https://api-cms-stg.betstarters.cloud/cms',
    games: 'https://api-games-stg.betstarters.cloud',
    identity: 'https://api-identity-stg.betstarters.cloud',
    promo: 'https://api-promo-stg.betstarters.cloud',
    reports: 'https://api-reports-stg.betstarters.cloud',
    sportsbook: 'https://api-sportsbook-stg.betstarters.cloud'
  },
  appVersion: packageInfo.version,
  bookmakerId: 4,
  defaultLangCode: 'en',
  externalScripts: [],
  trackers: [],
  features: {
    allowThemeSwitch: false,
    bottomSheet: false,
    creationModes: {
      agent: 'agentFast',
      player: 'playerFast',
      shop: 'shopFast'
    },
    games: {},
    passwordRecovery: {
      email: true,
      phone: false
    },
    registrationModes: 'emailFull',
    validators: {}
  },
  layout: {
    header: 'center',
    footer: 'center',
  },
  langs: [{
    code: 'en',
    name: 'English'
  }],
  live: {},
  production: false,
  project: 'betstarters.com',
  depositPage: 'me/balances/deposit',
  homePage: 'landing',
  theme: {
    defaultDark: true
  },
  webAppId: Webapps.bart,
  websocketUrl: {
    coupon: 'wss://wss-cloud.betstarters.cloud:8004',
    live: 'wss://wss-cloud.betstarters.cloud:8001'
  },
  timeZone: 'Europe/Malta'
};
